<template>
  <div class="battery-stock">
    <div class="title">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="代理商">
          <AgentTree
            :props="props"
            v-model="searchForm.unitNo"
            placeholder="请选择组织"
            style="width: 300px"
          ></AgentTree>
        </el-form-item>
        <el-form-item label="日期选择">
          <el-date-picker
            v-model="searchForm.time"
            type="date"
            placeholder="请选择日期"
            value-format="timestamp"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <!-- TO DO -->
          <el-button type="primary" @click="exportBtn">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="unitName" label="代理商"> </el-table-column>
        <el-table-column prop="batteryCount" label="电池数量">
        </el-table-column>
        <el-table-column prop="stockCount" label="库存数量" width="200">
        </el-table-column>
        <el-table-column prop="shopCount" label="门店数量"> 
          <template slot-scope="{row}">
            {{row.shopCount ? row.shopCount : '--'}}
          </template>
        </el-table-column>
        <el-table-column prop="leaseCount" label="租赁中电池">
        </el-table-column>
        <el-table-column prop="freeCount" label="空闲电池"> </el-table-column>
        <el-table-column prop="time" label="统计时间"> </el-table-column>

      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { statisticList,deviceStockExport } from "@/api/agent";
import { queryHaiLiAgent } from "@/api/common";
import AgentTree from "@/components/AgentTree";

export default {
  data() {
    return {
      /** 查询 */
      searchForm: {
        unitNo: [], // 代理商编号
        time: ""
      },
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      agentList: [], //代理商数据列表
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearch()
      }
    };
    this.statisticList();
    this.queryHaiLiAgent();
  },
  components: {
    AgentTree,
  },
  methods: {
    async exportBtn(){
      try{
        const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
        const params = {
          ...this.searchForm,
          unitNo
        };
        const res = await deviceStockExport(params);
        if(+res.result === 1){
          this.$router.push('/leaseholdMgmt/exportTask');
        }
      }catch(e){
        console.log(e);
      }
    },
    onSearch() {
      this.beginIndex = 1;
      this.statisticList();
    },
    /**获取代理商数据*/
    async queryHaiLiAgent() {
      const params = {
        role: 1
      };
      const res = await queryHaiLiAgent(params);
      this.agentList = res.ldata;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.statisticList();
    },
    /*获取列表 */
    async statisticList() {
      let page = this.beginIndex;
      page = page - 1;
      if (!this.searchForm.time) {
        this.searchForm.time = new Date().getTime();
      }
      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
      const params = {
        ...this.searchForm,
        unitNo,
        pageSize: this.pageSize,
        beginIndex: page
      };

      const res = await statisticList(params);
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    goAddGoods() {
      this.$router.push("/leaseholdMgmt/addGoods");
    }
  }
};
</script>

<style scoped lang="less">
.battery-stock {
  padding-bottom: 80px;
}
</style>
