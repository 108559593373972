import axios from "../js/ajax";
/** 代理商列表 */
export const getAgentList = params =>
  axios.get("/managementPlatform/operation/queryHaiLiUnit", {
    params,
    loading: true
  });
/* 新增代理商 */
export const addAgent = params =>
  axios.post("/managementPlatform/operation/createOrUpdateUnit", params);
/* 配置项 */
export const updateSet = params =>
  axios.get("/managementPlatform/operation/updateUnitSettlementPeriod", {
    params
  });
/** 删除 */
export const delAgent = params =>
  axios.get("/managementPlatform/operation/deleteHaiLiUnitMember", { params });
/** 获取代理商门店 树形结构 */
export const getAgentTree = params =>
  axios.get("/createOrEditLoanReceipt/queryHaiLiUnitTree", { params });

/** 电池库存管理 */
export const statisticList = params =>
  axios.get("/managementPlatform/device/statistic/list", {
    params,
    loading: true
  });
/** 电池库存导出 */
export const deviceStockExport = params =>
  axios.get("/electricity/lease/data/deviceStock/export", { params });

  